import React from 'react'
import LoadScripts from '../Components/LoadScripts'
import { Link } from 'react-router-dom'
import ManageCart from '../Controllers/ManageCart';
import { IMG_BASE_URL } from '../config';

import { Helmet } from 'react-helmet';


export default function Checkout1() {
  const { handleOnChangeValue, addToCart, cartItems, handleDeleteItem } = ManageCart();
  const totalBilling = cartItems.reduce((acc, item) => acc + (item.SalePrice * item.quantity), 0);
  const discount = 0; // 159,00 in Rs.
  const shipping = 0;  // 30,00 in Rs.
  const gst = 0;       // 59,00 in Rs.

  // Calculate the final amount after subtracting discount, shipping, and GST
  const finalAmount = totalBilling - (discount/100) + (shipping/100) + (gst/100);
  return (
    <>
      <Helmet>
        <title>Cart Items | Checkout | Citizen Plastic</title>
        <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        <meta property="og:title" content="Cart Items | Checkout | Citizen Plastic" />
        <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        </Helmet>
      <LoadScripts />
      <div>
        <section className="main-header" style={{ backgroundImage: 'url(/assets/images/gallery-2.jpg)' }}>
          <header>
            <div className="container text-center">
              <h2 className="h2 title">Checkout</h2>
              {/* <ol className="breadcrumb breadcrumb-inverted">
                <li><Link to="/"><span className="icon icon-home" /></Link></li>
                <li><Link className="active" to="/checkout1">Cart items</Link></li>
                <li><Link to="/checkout2">Delivery</Link></li>
                <li><Link to="/checkout3">Payment</Link></li>
                <li><Link to="/checkout4">Receipt</Link></li>
              </ol> */}
            </div>
          </header>
        </section>
        {/* ========================  Checkout ======================== */}
        <div className="step-wrapper">
          <div className="container">
            <div className="stepper">
              <ul className="row">
                <li className="col-md-3 active">
                  <span data-text="Cart items" />
                </li>
                <li className="col-md-3">
                  <span data-text="Information" />
                </li>
                <li className="col-md-3">
                  <span data-text="Comfirmation" />
                </li>
                <li className="col-md-3">
                  <span data-text="Receipt" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <section className="checkout">
          <div className="container">
            <header className="hidden">
              <h3 className="h3 title">Checkout - Step 1</h3>
            </header>
            {/* ========================  Cart wrapper ======================== */}
            <div className="cart-wrapper">
              {/*cart header */}
              <div className="cart-block cart-block-header clearfix">
                <div>
                  <span>Product</span>
                </div>
    
                <div>
                  <span>Name</span>
                </div>
    
               
                <div className='text-left'>
                  <span>Color</span>
                </div>
                
                <div className="text">
                  <span>Quantity</span>
                </div>
                <div className="text-right">
                  <span>Price</span>
                </div>
              </div>
              {/*cart items*/}
              <div className="clearfix">
                
                {cartItems.length > 0 ? (
                  cartItems.map((item, index) => (
                    <div className="cart-block cart-block-item clearfix" key={index}>
                      <div className="image">
                        <Link to={`/product/${item.Id}/${item.Slug}`}> <center><img src={`${IMG_BASE_URL}${item.attimage}`}  alt style={{ width : 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100px'}} /></center></Link>
                      </div>
                      <div className="title">
                        <div className="h4">{item.Name} ({item.ArticleNo})</div>
                        <div>{item.description}</div>
                      </div>
                      <div className="title">
                        <div className="h4">{item.color}</div>
                      </div>
                      <div className="quantity">
                        <input
                          type="number"
                          value={item.quantity}
                          min="1"
                          className="form-control form-quantity"
                          onChange={(event) => handleOnChangeValue(event, index)}
                        />
                      </div>
                      <div className="price">
                        <span className="final h3">Rs. {(parseFloat(item.SalePrice) * item.quantity).toFixed(2)}</span>
                        {/* <span className="discount">Rs. {item.originalPrice}</span> */}
                      </div>
                      <span
                        className="icon icon-cross icon-delete"
                        onClick={() => handleDeleteItem(index)}
                      />
                    </div>
                  ))
                ) : (
                  <div className='cart-block cart-block-item clearfix'>
                    <h2 align="center">Empty Cart</h2>
                  </div>
                )}
              </div>
              {/*cart prices */}
              <div className="clearfix">
                <div className="cart-block cart-block-footer clearfix">
                  <div>
                    <strong>Discount</strong>
                  </div>
                  <div>
                    <span>Rs. {discount / 100}</span>
                  </div>
                </div>
                {/* <div className="cart-block cart-block-footer clearfix">
                  <div>
                    <strong>Shipping</strong>
                  </div>
                  <div>
                    <span>Rs. {shipping / 100}</span>
                  </div>
                </div>
                <div className="cart-block cart-block-footer clearfix">
                  <div>
                    <strong>GST</strong>
                  </div>
                  <div>
                    <span>Rs. {gst / 100}</span>
                  </div>
                </div> */}
              </div>
              {/*cart final price */}
              <div className="clearfix">
                <div className="cart-block cart-block-footer cart-block-footer-price clearfix">
                  <div>
                    <span className="checkbox">
                      <input type="checkbox" id="couponCodeID" />
                      <label htmlFor="couponCodeID">Promo code</label>
                      <input type="text" className="form-control form-coupon" placeholder="Enter your coupon code" />
                    </span>
                  </div>
                  <div>
                    <div className="h2 title">Rs.{finalAmount.toFixed(2)}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* ========================  Cart navigation ======================== */}
            <div className="clearfix">
              <div className="row">

              
                <div className="col-xs-6">
                  <Link to="/shop" className="btn btn-clean-dark"><span className="icon icon-chevron-left" /> Shop more</Link>
                </div>
                <div className="col-xs-6 text-right">
                {cartItems.length > 0 && (
                  <Link to="/checkout/information" className="btn btn-main"><span className="icon icon-cart" /> Proceed to delivery</Link>
                )}
                </div>
              </div>
            </div>
          </div> {/*/container*/}
        </section>
      </div>

    </>
  )
}
// {/* <div className="cart-block cart-block-item clearfix">
// <div className="image">
//   <a href="product.html"><img src="assets/images/product-1.png" alt="pic" /></a>
// </div>
// <div className="title">
//   <div className="h4"><a href="product.html">Green corner</a></div>
//   <div>Green corner</div>
// </div>
// <div className="quantity">
//   <input type="number" defaultValue={2} className="form-control form-quantity" />
// </div>
// <div className="price">
//   <span className="final h3">Rs. 1.998</span>
//   <span className="discount">Rs. 2.666</span>
// </div>
// {/*delete-this-item*/}
// <span className="icon icon-cross icon-delete" />
// </div>
// <div className="cart-block cart-block-item clearfix">
// <div className="image">
//   <a href="product.html"><img src="assets/images/product-2.png" alt="pic" /></a>
// </div>
// <div className="title">
//   <div className="h4"><a href="product.html">Green corner</a></div>
//   <div>Green corner</div>
// </div>
// <div className="quantity">
//   <input type="number" defaultValue={2} className="form-control form-quantity" />
// </div>
// <div className="price">
//   <span className="final h3">Rs. 1.998</span>
//   <span className="discount">Rs. 2.666</span>
// </div>
// {/*delete-this-item*/}
// <span className="icon icon-cross icon-delete" />
// </div>
// <div className="cart-block cart-block-item clearfix">
// <div className="image">
//   <a href="product.html"><img src="assets/images/product-3.png" alt="pic" /></a>
// </div>
// <div className="title">
//   <div className="h4"><a href="product.html">Green corner</a></div>
//   <div>Green corner</div>
// </div>
// <div className="quantity">
//   <input type="number" defaultValue={2} className="form-control form-quantity" />
// </div>
// <div className="price">
//   <span className="final h3">Rs. 1.998</span>
//   <span className="discount">Rs. 2.666</span>
// </div>
// {/*delete-this-item*/}
// <span className="icon icon-cross icon-delete" />
// </div> */}