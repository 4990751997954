import React, { useState } from 'react'
import LoadScripts from '../Components/LoadScripts'
import { Helmet } from 'react-helmet';
import GetInvoice from '../FetchApi/GetInvoice';


export default function () {
  const [orderId, setOrderId] = useState('');
  const { trackOrder, order } = GetInvoice();
  const [show, setShow] = useState(false);
  const tOrder = (e) => {
    e.preventDefault(); // Prevent the default form submission

    trackOrder(orderId);
    setShow(true);
  }
  return (
    <>
      <Helmet>
        <title>Track Order | Citizen Plastic</title>
        <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        <meta property="og:title" content="About Us | Citizen Plastic" />
        <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
      </Helmet>
      <LoadScripts />
      <div>
        <section className="main-header main-header-blog" style={{ backgroundImage: 'url(assets/images/breadcrumb.jpg)' }}>
          <header>
            <div className="container text-center">
              <h2 className="h2 title">Track Order</h2>
              <p className="subtitle">Track your Order</p>
            </div>
          </header>
        </section>
        <section className="login-wrapper login-wrapper-page">
          <div className="container">
            <header>
              <div className="row">
                <div className="col-md-offset-2 col-md-8 text-center">
                  <h2 className="title">Track Your Order by Entrying the Order Now</h2>
                </div>
              </div>
            </header>
            <div className="row">
              <div className="col-md-offset-2 col-md-8">
                <div className="form-group">
                  <label>Order ID</label>
                  <input type="text" className="form-control" onChange={(e) => setOrderId(e.target.value)} placeholder="Enter your order ID" required />
                </div>
                <div className="form-group">
                  <button type="submit" onClick={tOrder} className="btn btn-main">Track Order</button>
                </div>
              </div>
            </div>
            {show ? <>
              {order.Order ? <>
                {order.Status === 200 && order.OrderStausId != 0 ?
                  <>
                    <div className="row">
                      <div className="cart-wrapper">
                        <div className="note-block">
                          <div className="row">

                            <div className="col-md-12">
                              <div className="white-block">

                                <div className="h4">Order Info</div>
                                <hr />
                                <div className="alert alert-info" role="alert">
                                  {order.Order.Name}
                                </div>

                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <strong>Order No</strong> <br />
                                      <span>{order.Order.OrderNo}</span>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <strong>Net Total</strong><br />
                                      <span>{order.Order.NetTotal}</span>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <strong>Quantity</strong><br />
                                      <span>{order.Order.Quantity}</span>
                                    </div>
                                  </div>
                                </div></div>
                            </div></div>
                        </div>
                      </div>
                    </div>
                  </>
                  : <>
                    <div className="alert alert-danger" role="alert">
                      No Order Found
                    </div></>}
              </> : <>
                <div className="alert alert-danger" role="alert">
                  No Order Found
                </div></>}
            </> : null}
          </div>
        </section>

      </div>
    </>
  )
}
// {/* <div className="cart-wrapper">
// <div className="note-block">
//   <div className="row">

//     <div className="col-md-12">
//       <div className="white-block">

//         <div className="h4">Shipping info</div>
//         <hr />
//         <div className="row">
//           <div className="col-md-6">
//             <div className="form-group">
//               <strong>Name</strong> <br />
//               {/* <span>{customer.FirstName} {customer.LastName}</span> */}
//             </div>
//           </div>
//           <div className="col-md-6">
//             <div className="form-group">
//               <strong>Email</strong><br />
//               {/* <span>{customer.EmailAddress}</span> */}
//             </div>
//           </div>
//           <div className="col-md-6">
//             <div className="form-group">
//               <strong>Phone</strong><br />
//               {/* <span>{customer.PhoneNo}</span> */}
//             </div>
//           </div>
//           <div className="col-md-6">
//             <div className="form-group">
//               <strong>Zip</strong><br />
//               {/* <span>{customer.ZipCode}</span> */}
//             </div>
//           </div>
//           <div className="col-md-6">
//             <div className="form-group">
//               <strong>City</strong><br />
//               {/* <span>{customer.CityId}</span> */}
//             </div>
//           </div>
//           <div className="col-md-6">
//             <div className="form-group">
//               <strong>Address</strong><br />
//               {/* <span>{customer.Address}</span> */}
//             </div>
//           </div>
//           <div className="col-md-6">
//             <div className="form-group">
//               <strong>Company name</strong><br />
//               {/* <span>{customer.CompanyName}</span> */}
//             </div>
//           </div>

//         </div>
//       </div> {/*/col-md-6*/}
//     </div>
//   </div>
// </div>
// </div>
// {/* ========================  Cart wrapper ======================== */}
// <div className="cart-wrapper">
// {/*cart header */}
// <div className="cart-block cart-block-header clearfix">
//   <div>
//     <span>Product</span>
//   </div>
//   <div>
//     <span>&nbsp;</span>
//   </div>
//   <div>
//     <span>Quantity</span>
//   </div>
//   <div className="text-right">
//     <span>Price</span>
//   </div>
// </div>
// {/*cart items*/}
// <div className="clearfix">
//   {cartItems.length > 0 ? (
//     cartItems.map((item) => (
//       <div className="cart-block cart-block-item clearfix" key={item.Id}>
//         <div className="image">
//           <Link to={`/product/${item.Id}/${item.Slug}`}> <center><img src={`${IMG_BASE_URL}${encodeURIComponent(item.attimage)}`} alt="pic" style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100px' }} /></center></Link>
//         </div>
//         <div className="title">
//           <div className="h4"><a href="product.html">{item.Name}</a></div>
//           <div>{item.scName}</div>
//         </div>
//         <div className="quantity">
//           <strong>{item.quantity}</strong>
//         </div>
//         <div className="price">
//           <span className="final h3">Rs.  {(parseFloat(item.SalePrice) * item.quantity).toFixed(2)}</span>
//         </div>
//       </div>
//     ))
//   ) : (
//     <>

//       {back && back()} {/* Call the back function if it's defined */}

//       <div className='cart-block cart-block-item clearfix'>
//         <h2 align="center">Empty Cart</h2>
//       </div>
//     </>

//   )}

//   {/* <div className="cart-block cart-block-item clearfix">
// <div className="image">
// <a href="product.html"><img src="assets/images/product-2.png" alt /></a>
// </div>
// <div className="title">
// <div className="h4"><a href="product.html">Green corner</a></div>
// <div>Green corner</div>
// </div>
// <div className="quantity">
// <strong>1</strong>
// </div>
// <div className="price">
// <span className="final h3">Rs. 1.998</span>
// <span className="discount">Rs. 2.666</span>
// </div>
// </div>
// <div className="cart-block cart-block-item clearfix">
// <div className="image">
// <a href="product.html"><img src="assets/images/product-3.png" alt /></a>
// </div>
// <div className="title">
// <div className="h4"><a href="product.html">Green corner</a></div>
// <div>Green corner</div>
// </div>
// <div className="quantity">
// <strong>1</strong>
// </div>
// <div className="price">
// <span className="final h3">Rs. 1.998</span>
// <span className="discount">Rs. 2.666</span>
// </div>
// </div>
// <div className="cart-block cart-block-item clearfix">
// <div className="image">
// <a href="product.html"><img src="assets/images/product-3.png" alt /></a>
// </div>
// <div className="title">
// <div className="h4"><a href="product.html">Green corner</a></div>
// <div>Green corner</div>
// </div>
// <div className="quantity">
// <strong>1</strong>
// </div>
// <div className="price">
// <span className="final h3">Rs. 1.998</span>
// <span className="discount">Rs. 2.666</span>
// </div>
// </div> */}
// </div>
// {/*cart prices */}
// <div className="clearfix">
//   <div className="cart-block cart-block-footer clearfix">
//     <div>
//       <strong>Discount</strong>
//     </div>
//     <div>
//       <span>Rs. {discount / 100}</span>
//     </div>
//   </div>
//   <div className="cart-block cart-block-footer clearfix">
//     <div>
//       <strong>Shipping</strong>
//     </div>
//     <div>
//       <span>Rs. {shipping / 100}</span>
//     </div>
//   </div>
//   <div className="cart-block cart-block-footer clearfix">
//     <div>
//       <strong>GST</strong>
//     </div>
//     <div>
//       <span>Rs. {gst / 100}</span>
//     </div>
//   </div>
// </div>
// {/*cart final price */}
// <div className="clearfix">
//   <div className="cart-block cart-block-footer clearfix">
//     <div>
//       <strong>Promo code included!</strong>
//     </div>
//     <div>
//       <div className="h2 title">Rs. {finalAmount.toFixed(2)}</div>
//     </div>
//   </div>
// </div>
// </div> */}