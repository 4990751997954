import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <>
    <footer>
          <div className="container">
            {/*footer showroom*/}
            <div className="footer-showroom">
              <div className="row">
                <div className="col-sm-8">
                  <h2>Visit our showroom</h2>
                  <p>G.T. Road, near Nadra Office, Gujranwala, Punjab 52250</p>
                  <p>Sun-Thu and Sat: 09:00–17:00
                   &nbsp; &nbsp; | &nbsp; &nbsp; Friday: Closed</p>
                   {/* <div className="call-us"><span className="icon icon-envelope" /> shop@citizen.com.pk</div> */}
                </div>
                <div className="col-sm-4 text-center">
                  <a href="https://maps.app.goo.gl/fvJUDqjUxzDdUWM89" target='_blank' className="btn btn-clean"><span className="icon icon-map-marker" /> Get directions</a>
                  {/* <div className="call-us h4"><span className="icon icon-phone-handset" /> +92-333-0348378</div>
                  <div className="call-us h4"><span className="icon icon-phone-handset" /> +92-55-111-936-936</div> */}
                  
                </div>
              </div>
            </div>
            {/*footer links*/}
            <div className="footer-links">
              <div className="row">
                <div className="col-sm-4 col-md-2">
                  <h5>Browse by</h5>
                  <ul>
                    <li><Link to="/shop">Brand</Link></li>
                    <li><Link to="/shop">Product</Link></li>
                    <li><Link to="/shop">Category</Link></li>
                  </ul>
                </div>
                <div className="col-sm-4 col-md-2">
                  <h5>Quick Links</h5>
                  <ul>
                    <li><Link to="/about">About Us</Link></li>
                    <li><Link to="/shop">Shop</Link></li>
                    <li><Link to="/contact">Contact Us</Link></li>
                  </ul>
                </div>
                <div className="col-sm-4 col-md-3">
                  <h5>Help</h5>
                  <ul>
                    <li><span className="icon icon-phone-handset" /> +92-333-0348378</li>
                    <li><span className="icon icon-phone-handset" /> +92-55-111-936-936</li>
                    <li><span className="icon icon-envelope" /> shop@citizen.com.pk</li>
                  </ul>
                </div>
                <div className="col-sm-4 col-md-2">
                  <h5>Downloads</h5>
                  <ul>
                    <li><a href="/assets/Catalogs/catalog1.pdf" target='_blank'>Catalog</a></li>
                  </ul>
                </div>
                {/* <div className="col-sm-4 col-md-2">
                  <h5>Our company</h5>
                  <ul>
                    <li><a href="/">About</a></li>
                    <li><a href="/">News</a></li>
                    <li><a href="/">Contact</a></li>
                  </ul>
                </div> */}
                {/* <div className="col-sm-12 col-md-6">
                  <h5>Sign up for our newsletter</h5>
                  <p><i>Add your email address to sign up for our monthly emails and to receive promotional offers.</i></p>
                  <div className="form-group form-newsletter">
                    <input className="form-control" type="text" name="email" defaultValue placeholder="Email address" />
                    <input type="submit" className="btn btn-clean btn-sm" defaultValue="Subscribe" />
                  </div>
                </div> */}
              </div>
            </div>
            {/*footer social*/}
            <div className="footer-social">
              <div className="row">
                <div className="col-sm-8">
                  <Link to="/trackorder"> Track Order</Link> &nbsp; | &nbsp;<Link to="/storeLocator"> Store Locator</Link> &nbsp; | &nbsp; <Link to="/PrivacyPolicy">Privacy policy</Link> &nbsp; | &nbsp; <Link to="/ReturnPolicy">Return Policy</Link> &nbsp; | &nbsp; <Link to="/TermsCondition">Terms & Contidions</Link>
                </div>
                <div className="col-sm-4 links">
                  <ul>
                    {/* <li><a href="/"><i className="fa fa-facebook" /></a></li>
                    <li><a href="/"><i className="fa fa-twitter" /></a></li>
                    <li><a href="/"><i className="fa fa-google-plus" /></a></li>
                    <li><a href="/"><i className="fa fa-youtube" /></a></li>
                    <li><a href="/"><i className="fa fa-instagram" /></a></li> */}

                    <li><a href="https://www.facebook.com/CitizenPlastic/" target='_blank'><i className="fa fa-facebook" /></a></li>
                <li><a href="https://www.instagram.com/citizenplastic/" target='_blank'><i className="fa fa-instagram" /></a></li>
                <li><a href="https://twitter.com/CitizenPlastic" target='_blank'><i className="fa fa-twitter" /></a></li>
                <li><a href="https://www.linkedin.com/in/citizen-plastic-62707b152/" target='_blank'><i className="fa fa-linkedin" /></a></li>
                <li><a href="https://www.youtube.com/@citizenplasticfurniture" target='_blank'><i className="fa fa-youtube" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
    </>
  )
}
