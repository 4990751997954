import React from 'react'
import LoadScripts from '../Components/LoadScripts'
import { Helmet } from 'react-helmet';


export default function () {
  return (
    <>
    <Helmet>
      <title>Return Policy | Citizen Plastic</title>
      <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
      <meta property="og:title" content="Return Policy | Citizen Plastic" />
      <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
      </Helmet>
  <LoadScripts />
  <div>
<section className="main-header main-header-blog" style={{backgroundImage: 'url(assets/images/breadcrumb.jpg)'}}>
  <header>
    <div className="container text-center">
      <h2 className="h2 title">Return Policy</h2>
      {/* <p className="subtitle">Track your Order</p> */}
    </div>
  </header>
</section>
<section className="login-wrapper login-wrapper-page">
    <div className="container">
    {/* <header>
            <div className="row">
              <div className="col-md-offset-2 col-md-8 text-center">
                <h2 className="title">Track Your Order by Entrying the Order Now</h2>
              </div>
            </div>
          </header> */}
          <div className="row">
            <div className="col-md-12">
            <p style={{ fontSize: '25px'}}>Citizen.com.pk is providing the reliable refund and return policy for satisfying the customer regarding the service of online shopping in Pakistan.
            </p>
            <ol style={{ fontSize: '25px' }}>

            <li>	Customers can also get the refund or return of products that may be received as damaged, faulted, malfunctioned, or wrong.</li>
            <li>	Customers have to claim for refund or return within 3 days after receiving the product, otherwise the return or refund request will consider as invalid (Change of mind will not be accepted).</li>
            <li>	In case of receiving damaged products, customer will send the product back to us and the refund process will further take 2 to 3 working days. Team will check and verify the damage product before any proceeding of return or refund.</li>
            <li>	In case of malfunctioned products, ccustomer needs to provide us complete detail within 3 days of delivery. We will share the detail with the supplier and confirm the validity (refund or return) within 5 to 10 working days.</li>
        </ol>
            </div>
          </div>
    </div>
  </section>
</div>
</>
  )
}
