import React from 'react'
import LoadScripts from '../Components/LoadScripts'
import { Helmet } from 'react-helmet';


export default function About() {
  return (
    <>
      <Helmet>
        <title>About Us | Citizen Plastic</title>
        <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        <meta property="og:title" content="About Us | Citizen Plastic" />
        <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
      </Helmet>
      <LoadScripts />
      <div>
        <section className="main-header main-header-blog" style={{ backgroundImage: 'url(assets/images/breadcrumb.jpg)' }}>
          <header>
            <div className="container text-center">
              <h2 className="h2 title">About Us</h2>
              <p className="subtitle">A bit of history</p>
            </div>
          </header>
        </section>
        {/* ================== Intro section default ================== */}
        <section className="our-team">
          <div className="container">
            {/* === Our team header === */}
            <div className="row">
              <div className="col-md-offset-2 col-md-8 text-center">
                <h1 className="h2 title">Welcome to Citizen Plastic Furniture</h1>
                <div className="text">
                  <p>At Citizen Plastic Furniture, we believe that great furniture should not only be functional but also a reflection of innovation, durability, and comfort. Since our inception, we have been dedicated to redefining the standards of furniture with our cutting-edge designs and premium-quality plastic materials.</p>
                </div>
              </div>
            </div>
            {/* === Our team === */}

          </div>
        </section>
        {/* ================== Banner ================== */}
        <section className="banner" style={{ backgroundImage: 'url(assets/images/gallery-7.jpg)' }}>
          <div className="container">
            <div className="row">
              <div className="col-md-offset-2 col-md-8 text-center">
                <h1 className="h2 title">Innovation and Design</h1>
                <div className="text">
                  <p>
                    Innovation is at the heart of what we do. Our team of designers and engineers work tirelessly to push the boundaries of traditional furniture design. By integrating the latest trends and technologies, we offer products that are not only functional but also a statement of modern aesthetics.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ========================  Cards ======================== */}
        <section className="cards">
          {/* === cards header === */}
          {/* <header>
      <div className="row">
        <div className="col-md-offset-2 col-md-8 text-center">
          <h2 className="title">Design of interiors</h2>
          <div className="text">
            <p>Our services team produces interior design solutions</p>
          </div>
        </div>
      </div>
    </header> */}
          <div className="container">
            <div className="row">
              {/* === item === */}
              {/* <div className="col-md-8">
          <figure>
            <figcaption style={{backgroundImage: 'url(assets/images/blog-7.jpg)'}}>
              <img src="assets/images/blog-1.jpg" alt />
            </figcaption>
            <a href="#" className="btn btn-clean">Interior design</a>
          </figure>
        </div> */}
              {/* === item === */}
              {/* <div className="col-md-4">
          <figure>
            <figcaption style={{backgroundImage: 'url(assets/images/blog-2.jpg)'}}>
              <img src="assets/images/blog-2.jpg" alt />
            </figcaption>
            <a href="#" className="btn btn-clean">3D Modeling</a>
          </figure>
        </div> */}
              {/* === item === */}
              {/* <div className="col-md-4">
          <figure>
            <figcaption style={{backgroundImage: 'url(assets/images/blog-3.jpg)'}}>
              <img src="assets/images/blog-3.jpg" alt />
            </figcaption>
            <a href="#" className="btn btn-clean">Arhitect serives</a>
          </figure>
        </div> */}
              {/* === item === */}
              {/* <div className="col-md-4">
          <figure>
            <figcaption style={{backgroundImage: 'url(assets/images/blog-4.jpg)'}}>
              <img src="assets/images/blog-4.jpg" alt />
            </figcaption>
            <a href="#" className="btn btn-clean">Manufacturing</a>
          </figure>
        </div> */}
              {/* === item === */}
              {/* <div className="col-md-4">
          <figure>
            <figcaption style={{backgroundImage: 'url(assets/images/blog-5.jpg)'}}>
              <img src="assets/images/blog-5.jpg" alt />
            </figcaption>
            <a href="#" className="btn btn-clean">Planning</a>
          </figure>
        </div> */}
            </div> {/*/row*/}
          </div> {/*/container*/}
        </section>
        {/* ========================  History ======================== */}
        <section className="history">
          <div className="container">
            {/* === History header === */}
            <header>
              <div className="row">
                <div className="col-md-offset-2 col-md-8 text-center">
                  <h1 className="h2 title">A bit of history</h1>
                  <div className="text">
                    <p align="justify">
                      Citizen International Plastic Industry, established in 1987, has built a reputation for producing high-quality moulded furniture in Pakistan. Their diverse product range includes plastic chairs , revolving chairs such as computer and executive chairs, steel-plastic furniture including chairs and tables, sofas for waiting rooms, plastic and folding tables, school furniture like study chairs and desks, baby furniture.
                      Over the years, Citizen has gained a strong business reputation and has become one of the fastest-growing companies in the moulded plastic industry in Pakistan. The company holds a national leadership position in its product line and has successfully expanded its capacity at a rate surpassing the industry average, meeting the growing demand for its products.</p>
                  </div>
                </div>
              </div>
            </header>
         


            {/* === row item === */}
            <div className="row row-block">
                <div className="history-title">
                  {/* <h2 className="title">1987</h2> */}
                  {/* <p>The begining</p> */}
                </div>
              <div className="col-md-12 history-desc">
                <div className="h5 title" style={{ color: '#FFBB00'}}>CEO's Message</div>
                <p>
                Welcome to Citizen Plastic Industries!

At Citizen, we are driven by a singular purpose—to create innovative, durable, and sustainable plastic furniture that enhances the lives of our customers. Since our inception, we’ve been committed to delivering products that combine quality craftsmanship, modern design, and eco-conscious production processes.

Our success is built on the trust of our customers, partners, and a passionate team that continuously pushes the boundaries of what is possible. Whether you're looking for practical solutions for your home, office, or outdoor spaces, we pride ourselves on offering products that meet the highest standards of durability and comfort.

As we look ahead, we are deeply committed to making a positive impact not only through our products but also by embracing sustainable practices. By integrating recyclable materials and reducing our environmental footprint, we are doing our part to ensure a better future for the generations to come.

Thank you for choosing Citizen Plastic Industries. We are excited to continue this journey together, innovating and evolving to meet your needs.</p>
              </div>
            </div>
            <div className="row row-block">
                <div className="history-title">
                  {/* <h2 className="title">1987</h2> */}
                  {/* <p>The begining</p> */}
                </div>
              <div className="col-md-12 history-desc">
                <div className="h5 title" style={{ color: '#FFBB00'}}>Director's Message</div>
                <p>
                Welcome to Citizen Plastic Industries, where innovation, quality, and customer satisfaction are at the heart of everything we do.

                          As a director of this incredible organization, I am proud of the strides we have made in the plastic furniture industry. Our journey has been one of continuous learning, improvement, and an unwavering commitment to providing our customers with the finest, most durable, and aesthetically pleasing products. Our furniture is not just a testament to our craftsmanship but a reflection of our desire to improve the spaces where people live, work, and enjoy their lives.

                          At Citizen Plastic Industries, we prioritize forward-thinking. We invest in cutting-edge technology and sustainable practices to ensure our products are not only of the highest quality but also environmentally responsible. Our team is dedicated to innovation, always seeking new ways to blend style, functionality, and eco-friendliness into our product lines.

                          We owe our success to the support of our customers, partners, and the dedication of our team. Together, we continue to set new benchmarks in the plastic furniture industry and deliver exceptional value.

                          Thank you for being part of our journey. We look forward to continuing to serve you with integrity, excellence, and a passion for innovation.</p>
              </div>
            </div>
            <div className="row row-block">
              <div className="col-md-5 history-image" style={{ backgroundImage: 'url(assets/images/blog-3.jpg)' }}>
                <div className="history-title">
                  <h4 className="title">1987</h4>
                  <p>The begining</p>
                </div>
              </div>
              <div className="col-md-7 history-desc">
                <div className="h5 title">This is how it's began</div>
                <p>
                  Citizen, established in 1987, is a renowned manufacturer of high-class furniture in plastic and steel plastic materials. The company was founded by Haji Bashir Ullah (Late), who began production on a small scale. Through hard work, continuous focus, and strategic planning, Citizen has grown to become one of the largest plastic and steel plastic furniture manufacturers in Pakistan. Citizen is recognized for its commitment to continuous improvement and innovation.
                </p>
                <p>
                  The company has developed a strong reputation for advanced technology, high-quality products, and excellent after-sales service. With a robust sales network of over 100 outlets across Pakistan, Citizen continues to lead the industry in quality and customer satisfaction
                </p>
              </div>
            </div>
            {/* === row item === */}
            <div className="row row-block">
              <div className="col-md-5 history-image" style={{ backgroundImage: 'url(assets/images/blog-4.jpg)' }}>
                <div className="history-title">
                  <h4 className="title">Vision</h4>

                </div>
              </div>
              <div className="col-md-7 history-desc">
                <div className="h5 title">Our Vision</div>
                <p>
                  Our vision is to transform everyday living spaces with furniture that combines style, practicality, and sustainability. We strive to make high-quality, eco-friendly furniture accessible to everyone, ensuring that our products enhance your lifestyle while being kind to the planet.
                </p>
              </div>
            </div>
            {/* === row item === */}
            <div className="row row-block">
              <div className="col-md-5 history-image" style={{ backgroundImage: 'url(assets/images/blog-5.jpg)' }}>
                <div className="history-title">
                  <h4 className="title">Products</h4>

                </div>
              </div>
              <div className="col-md-7 history-desc">
                <div className="h5 title">Our Products</div>
                <p>

                  Our diverse range of products is designed to cater to various tastes and needs. From sleek and modern chairs to versatile tables and storage solutions, each piece is meticulously crafted to offer superior durability and timeless elegance. We use state-of-the-art technology and the highest quality materials to ensure that our furniture withstands the test of time, both in terms of style and performance.
                </p>

              </div>
            </div>
            {/* === row item === */}
            <div className="row row-block">
              <div className="col-md-5 history-image" style={{ backgroundImage: 'url(assets/images/blog-6.jpg)' }}>
                <div className="history-title">
                  <h4 className="title">Commit-<br />ment</h4>

                </div>
              </div>
              <div className="col-md-7 history-desc">
                <div className="h5 title">Our Commitment</div>
                <p>

                  At Citizen Plastic Furniture, we are committed to sustainability. We prioritize the use of recyclable materials and environmentally-friendly manufacturing processes to minimize our ecological footprint. Our goal is to create furniture that not only enhances your space but also contributes positively to the environment.
                </p>

              </div>
            </div>
          </div>
        </section>
        {/* ========================  Banner ======================== */}
        <section className="banner" style={{ backgroundImage: 'url(assets/images/gallery-2.jpg)' }}>
          <div className="container">
            <div className="row">
              <div className="col-md-offset-1 col-md-10 text-center">
                <h2 className="title">Customer Satisfaction</h2>
                <p>
                  Customer satisfaction is our top priority. We believe that great furniture starts with exceptional service. From the moment you explore our collection to the day your furniture arrives, we are here to ensure a seamless and enjoyable experience. Our dedicated support team is always available to assist with any inquiries and ensure that you are completely satisfied with your purchase.
                </p>

              </div>
            </div>
          </div>
        </section>

        {/* ======================== Quotes ======================== */}

      </div>

    </>
  )
}
