import React, {useState, useEffect} from 'react'

export default function ManageCart() {
    const [cartItems, setCartItems] = useState([]);
    const [quantity, setQuantity] = useState(1);
    
    useEffect(() => {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        setCartItems(cart);
      }, []);

 
      const handleOnChangeValue = (event, index) => {
        const newQuantity = Number(event.target.value);
        setQuantity(newQuantity);
    
        // Get the existing cart from localStorage
        const cart = JSON.parse(localStorage.getItem("cart")) || [];
    
        // Ensure the index is valid
        if (index !== -1 && index < cart.length) {
            // Update the product quantity based on its index
            cart[index].quantity = newQuantity;
    
            // Save the updated cart back to localStorage
            localStorage.setItem("cart", JSON.stringify(cart));
            setCartItems(cart);
        }
    };
    
    
    // const addToCart = (product, color) => {
    //     // Get the existing cart from localStorage
    //     const cart = JSON.parse(localStorage.getItem("cart")) || [];
        
    //     // Check if the product is already in the cart
    //     const existingProductIndex = cart.findIndex(item => item.Id === product.Id);
    
    //     if (existingProductIndex !== -1) {
    //       // If the product is already in the cart, notify the user
    //       // alert('Product is already in the cart. Quantity has been updated.');
            
    //     } else {
    //       // If the product is new to the cart, add it with the specified quantity
    //       cart.push({ ...product, quantity, color });
    //       // alert('Product added to cart');
          
          
    //       // Save the updated cart back to localStorage
    //       localStorage.setItem("cart", JSON.stringify(cart));
    //       setCartItems(cart);
    //     }
    //   };

  
  const addToCart = (product, color) => {
    // Get the existing cart from localStorage
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    
    // Find if the product with the same ID and color already exists in the cart
    const existingProductIndex = cart.findIndex(
        item => item.Id === product.Id && item.color === color
    );

    if (existingProductIndex !== -1) {
        // Calculate the total quantity after updating
        const newQuantity = cart[existingProductIndex].quantity + quantity;

        // Check if the total quantity exceeds the stock quantity
        if (newQuantity <= product.StockQty) {
            // Update the product quantity
            cart[existingProductIndex].quantity = newQuantity;
            // alert('Product quantity updated in cart.');
        } else {
            // If it exceeds stock, notify the user or handle the case accordingly
            alert(`Cannot add more than ${product.StockQty} items of this product to the cart.`);
            return;
        }
    } else {
        // Check if the quantity is within the stock quantity for new products
        if (quantity <= product.StockQty) {
            // Add the product as a new entry in the cart
            cart.push({ ...product, quantity, color });
            // alert('Product added to cart.');
        } else {
            // If it exceeds stock, notify the user or handle the case accordingly
            alert(`Cannot add more than ${product.StockQty} items of this product to the cart.`);
            return;
        }
    }

    // Save the updated cart back to localStorage
    localStorage.setItem("cart", JSON.stringify(cart));
    setCartItems(cart);
};

    // const handleDeleteItem = (productId) => {
    //     const updatedCartItems = cartItems.filter(item => item.Id !== productId);
    //     setCartItems(updatedCartItems);
    //     localStorage.setItem('cart', JSON.stringify(updatedCartItems));
    //   };
      const handleDeleteItem = (index) => {
        const updatedCartItems = [...cartItems];
        updatedCartItems.splice(index, 1); // Remove the item at the given index
        setCartItems(updatedCartItems);
        localStorage.setItem('cart', JSON.stringify(updatedCartItems));
    };

  
      
  return{ handleOnChangeValue, addToCart, cartItems, handleDeleteItem};
}
