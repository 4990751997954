import React from 'react'
import LoadScripts from '../Components/LoadScripts'

export default function Blog3() {
    return (
        <>
            <LoadScripts />
            <div>
                <div>
                    <section className="main-header main-header-blog" style={{ backgroundImage: 'url(/assets/images/breadcrumb.jpg)' }}>
                        <header>
                            <div className="container text-center">
                                <h2 className="h2 title">Blog</h2>
                                {/* <ol className="breadcrumb breadcrumb-inverted">
          <li><a href="index.html"><span className="icon icon-home" /></a></li>
          <li><a href="blog-grid.html">Blog Category</a></li>
          <li><a className="active" href="article.html">Decorating When You're...</a></li>
        </ol> */}
                            </div>
                        </header>
                    </section>
                    {/* ========================  Blog article ======================== */}
                    <section className="blog">
                        {/* === blog navigation === */}
                        <div className="blog-navigation">
                            {/* === nav previous === */}
                            <a className="nav-link prev" href="#">
                                <figure>
                                    <div className="image">
                                        <img src="/assets/images/blog-2.jpg" alt="Alternate Text" />
                                    </div>
                                    <figcaption>
                                        <div className="blog-title h6">Durability and Style: Why Citizen Plastic Chairs Are a Must-Have for Every Event</div>
                                    </figcaption>
                                </figure>
                            </a>
                            {/* === nav next === */}

                        </div> {/*/blog-navigation*/}
                        {/* ========================  Blog post ======================== */}
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
                                    <div className="blog-post">
                                        {/* === blog main image & entry info === */}
                                        <div className="blog-image-main">
                                            <img src="/assets/images/blog-1.jpg" alt />
                                        </div>
                                        <div className="blog-post-content">
                                            {/* === blog post title === */}
                                            {/* === blog post text === */}
                                            <div className="blog-post-text">
                                                <p>When planning an event, whether it's a wedding, corporate gathering, or casual outdoor celebration, one key element that can make or break the atmosphere is seating. Having chairs that are not only stylish but also durable can elevate the experience for your guests. That’s where Citizen Plastic Chairs come in—a perfect combination of durability, style, and practicality. Here's why Citizen Plastic Chairs should be your go-to choice for any event.</p>
                                                <h4>1. Built for Long-Lasting Durability</h4>
                                                <p>At Citizen, durability is at the forefront of every design. Made from high-quality, weather-resistant plastic, our chairs are built to withstand both indoor and outdoor events. Whether you're hosting a summer garden party or a formal indoor conference, Citizen Plastic Chairs are crafted to handle varying conditions without losing their structural integrity. With scratch-resistant and UV-protected finishes, these chairs maintain their look and functionality for years.</p>
                                                <h4>2. Versatile Designs for Any Event Theme</h4>
                                                <p>One of the biggest advantages of Citizen Plastic Chairs is their versatility. Available in a wide range of designs, colors, and finishes, these chairs can seamlessly blend into any event theme. Whether you need sleek, modern chairs for a corporate event or classic, elegant designs for a wedding, there’s a style in the Citizen range to meet your needs.</p>
                                                <h4>3. Easy Maintenance and Lightweight</h4>
                                                <p>Unlike wooden or metal chairs that may require regular maintenance, Citizen Plastic Chairs are incredibly easy to clean and maintain. Simply wipe them down with a damp cloth, and they’re ready for the next event. Moreover, their lightweight nature makes them easy to transport and arrange, saving you time and effort during setup and breakdown.</p>
                                                <h4>4. Budget-Friendly Without Compromising on Quality</h4>
                                                <p>Hosting an event can be expensive, but seating doesn't have to break the bank. Citizen Plastic Chairs offer an affordable solution without sacrificing quality or style. Whether you're hosting a large-scale event or a small intimate gathering, you can provide your guests with comfortable and attractive seating at a reasonable cost.</p>
                                                <h4>5. Stackable for Convenient Storage</h4>
                                                <p>If you're organizing events regularly, you know how important it is to have furniture that’s easy to store. Citizen Plastic Chairs are stackable, making them convenient for storage and transport. This feature is especially beneficial for venues and rental services, as it saves space and minimizes clutter.</p>
                                                <h4>6. Eco-Friendly Optionsy</h4>
                                                <p>In today’s world, sustainability is a growing concern, even in the events industry. Citizen Plastic Chairs are made from recyclable materials, contributing to an eco-friendly event setup. By choosing Citizen, you're not only investing in high-quality seating but also supporting sustainable practices.</p>
                                              
                                               
                                            </div>
                                            {/* === blog info === */}
                                        </div>
                                        {/* === blog comments === */}
                                    </div>{/*blog-post*/}
                                </div>{/*col-sm-8*/}
                            </div> {/*/row*/}
                        </div>{/*/container*/}
                    </section>
                </div>

            </div>

        </>
    )
}
