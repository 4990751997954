import React from 'react'
import StoreApi from '../Controllers/StoreApi';
import { useState, useEffect } from 'react';

export default function GetInvoice() {
    const { http} = StoreApi();
    const [invoice, setInvoice] = useState([]);
    const [order, setOrder] = useState([]);

    const getInvoices = async(ol) => {

        // const orderId = localStorage.getItem('oldorderNumber');
        if(ol) { 
        console.log(ol);
        await http.post('/getOrderInvoice', { 
         orderId: ol
        }, {
      })
      .then(response => {
          if (response.status === 200) {
            // console.log('API response:', response.data);
            setInvoice(response.data);
            console.log(invoice);
    
          } else {
            console.error("Fetching Order failed:", response.data.message);
          }
        })
        .catch(error => {
          console.error("Error during fetching Order:", error.message);
          // localStorage.clear();
          // navigate('/login');
        })
        .finally(() => {
        
      });      
    }  }

    const trackOrder = async(orderId) => {

      // const orderId = localStorage.getItem('oldorderNumber');
      if(orderId) { 
      // console.log(ol);
      await http.post('/trackOrder', { 
        orderId: orderId
      }, {
    })
    .then(response => {
        if (response.status === 200) {
          // console.log('API response:', response.data);
          setOrder(response.data);
          // console.log(invoice);
  
        } else {
          console.error("Fetching Order failed:", response.data.message);
        }
      })
      .catch(error => {
        console.error("Error during fetching Order:", error.message);
        // localStorage.clear();
        // navigate('/login');
      })
      .finally(() => {
      
    });      
  }  }


      return { trackOrder, order, getInvoices, invoice };
}
