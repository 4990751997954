import React, {useState} from 'react'
import StoreApi from '../Controllers/StoreApi';

export default function City() {
    const [cities, setCities] = useState([]);
    const [citydetails, setCityDetails] = useState([]);
    const { http}  = StoreApi();
    const fetchCities = () => {
        http.get("/fetchAllCities", { 
         })
          .then(response => {
            if (response.status === 200) {
              // console.log('API response:', response.data);
              setCities(response.data);
            
            } else {
              console.error("Fetching productDetail failed:", response.data.message);
            }
          })
          .catch(error => {
            console.error("Error during fetching productDetail:", error.message);
            // localStorage.clear();
            // navigate('/login');
          })
          .finally(() => {
            // setLoading(false);
          });

 
      } 
    const fetchCityDetail = () => {
        http.get("/fetchCityDetail", { 
         })
          .then(response => {
            if (response.status === 200) {
              // console.log('API response:', response.data);
              setCityDetails(response.data);
            
            } else {
              console.error("Fetching productDetail failed:", response.data.message);
            }
          })
          .catch(error => {
            console.error("Error during fetching productDetail:", error.message);
            // localStorage.clear();
            // navigate('/login');
          })
          .finally(() => {
            // setLoading(false);
          });

 
      } 
      return { fetchCities, cities, fetchCityDetail, citydetails  };
}
