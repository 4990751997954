import React, { useEffect, useState } from 'react'
import LoadScripts from '../Components/LoadScripts'
import { Link } from 'react-router-dom'
import Category from '../FetchApi/Category'
import Products from '../FetchApi/Products';
import { IMG_BASE_URL } from '../config';
import { Helmet } from 'react-helmet';





export default function Shop() {
  const { loadProducts, products } = Products();

  const { loadCategory, categories } = Category();
  // const aproducts = [
  //   {
  //     id: 1,
  //     title: 'Green corner',
  //     image: 'assets/images/product-1.png',
  //     oldPrice: 'Rs. 1499,-',
  //     newPrice: 'Rs. 1099,-',
  //     description: 'Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam lorem ea duo labore diam sit et consetetur nulla',
  //     label: '-50%',
  //     labelClass: 'label-info',
  //     categoryId: 9 // Added categoryId
  //   },
  //   {
  //     id: 2,
  //     title: 'Laura',
  //     image: 'assets/images/product-2.png',
  //     oldPrice: 'Rs. 3999,-',
  //     newPrice: 'Rs. 3499,-',
  //     description: 'Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam lorem ea duo labore diam sit et consetetur nulla',
  //     categoryId: 10 // Added categoryId
  //   },
  //   {
  //     id: 3,
  //     title: 'Nude',
  //     image: 'assets/images/product-3.png',
  //     newPrice: 'Rs. 2999,-',
  //     description: 'Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam lorem ea duo labore diam sit et consetetur nulla',
  //     categoryId: 10 // Added categoryId
  //   },
  //   {
  //     id: 4,
  //     title: 'Aurora',
  //     image: 'assets/images/product-4.png',
  //     newPrice: 'Rs. 299,-',
  //     description: 'Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam lorem ea duo labore diam sit et consetetur nulla',
  //     label: 'New',
  //     labelClass: 'label-warning',
  //     categoryId: 11 // Added categoryId
  //   },
  //   {
  //     id: 5,
  //     title: 'Seat chair',
  //     image: 'assets/images/product-6.png',
  //     newPrice: 'Rs. 896,-',
  //     description: 'Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam lorem ea duo labore diam sit et consetetur nulla',
  //     categoryId: 11 // Added categoryId
  //   },
  //   {
  //     id: 6,
  //     title: 'Dining set',
  //     image: 'assets/images/product-5.png',
  //     oldPrice: 'Rs. 1999,-',
  //     newPrice: 'Rs. 1499,-',
  //     description: 'Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam lorem ea duo labore diam sit et consetetur nulla',
  //     label: 'New',
  //     labelClass: 'label-warning',
  //     categoryId: 12 // Added categoryId
  //   },
  //   {
  //     id: 7,
  //     title: 'Dining set',
  //     image: 'assets/images/product-5.png',
  //     oldPrice: 'Rs. 1999,-',
  //     newPrice: 'Rs. 1499,-',
  //     description: 'Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam lorem ea duo labore diam sit et consetetur nulla',
  //     label: 'New',
  //     labelClass: 'label-warning',
  //     categoryId: 13 // Added categoryId
  //   },
  //   {
  //     id: 8,
  //     title: 'Dining set',
  //     image: 'assets/images/product-5.png',
  //     oldPrice: 'Rs. 1999,-',
  //     newPrice: 'Rs. 1499,-',
  //     description: 'Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam lorem ea duo labore diam sit et consetetur nulla',
  //     label: 'New',
  //     labelClass: 'label-warning',
  //     categoryId: 16 // Added categoryId
  //   },
  //   {
  //     id: 9,
  //     title: 'Dining set',
  //     image: 'assets/images/product-5.png',
  //     oldPrice: 'Rs. 1999,-',
  //     newPrice: 'Rs. 1499,-',
  //     description: 'Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam lorem ea duo labore diam sit et consetetur nulla',
  //     label: 'New',
  //     labelClass: 'label-warning',
  //     categoryId: 16 // Added categoryId
  //   },
  //   {
  //     id: 10,
  //     title: 'Dining set',
  //     image: 'assets/images/product-5.png',
  //     oldPrice: 'Rs. 1999,-',
  //     newPrice: 'Rs. 1499,-',
  //     description: 'Gubergren amet dolor ea diam takimata consetetur facilisis blandit et aliquyam lorem ea duo labore diam sit et consetetur nulla',
  //     label: 'New',
  //     labelClass: 'label-warning',
  //     categoryId: 16 // Added categoryId
  //   }
  // ];


  const pagination = [1, 2, 3, 4, 5];





  // Use the function to set the initial state
  const [selectedCategory, setSelectedCategory] = useState();
  const [filteredProducts, setFilteredProducts] = useState(products);

  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 6;

  // Calculate total pages
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  // Get products for the current page
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  // const changeSelectedCategory = (id) => {
  //   setFilteredProducts();
  //   setSelectedCategory();
  //   console.log(id);
  //   setSelectedCategory(id);
  //   if (id) {
  //     const newFilteredProducts = products.products.filter(product => product.SubCategoryId === selectedCategory);
  //     setFilteredProducts(newFilteredProducts);

  //   }
  //   else {
  //     setFilteredProducts(products.products);

  //   }

  // }
  const changeSelectedCategory = (id) => {
    setSelectedCategory(id); // Update the selected category in state
    setCurrentPage(1); // Reset the current page to 1 when a category is changed
  
    // Use `id` directly instead of `selectedCategory` to filter products
    if (id) {
      const newFilteredProducts = products.products.filter(product => product.SubCategoryId === id);
      setFilteredProducts(newFilteredProducts);
    } else {
      // If no category is selected (id is null or undefined), show all products
      setFilteredProducts(products.products);
    }
  };

  // Update filtered products when the selected category changes
  useEffect(() => {
    loadCategory();
    loadProducts();
    // console.log(categories.categories);

    // console.log(products.products);
  }, []);
  useEffect(() => {
    if (products?.products) {
      setFilteredProducts(products.products);
    }
  }, [products]);


  const hasCategories = Array.isArray(categories.categories);
  const hasProducts = Array.isArray(products.products);

  return (
    <>
      <Helmet>
        <title>Shop | Citizen Plastic</title>
        <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        <meta property="og:title" content="Shop | Citizen Plastic" />
        <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
      </Helmet>
      <LoadScripts />
      <section className="main-header" style={{ backgroundImage: 'url(/assets/images/breadcrumb.jpg)' }}>
        <header>
          <div className="container">
            <h1 className="h2 title">Shop</h1>
            <ol className="breadcrumb breadcrumb-inverted">
              <li><Link to="/"><span className="icon icon-home" /></Link></li>
              <li><Link className="active" to="/shop">Shop</Link></li>

            </ol>
          </div>
        </header>
      </section>
      {/* ========================  Icons slider ======================== */}
      <section className="owl-icons-wrapper">
        {/* === header === */}
        <header className="hidden">
          <h2>Product categories</h2>
        </header>
     
      </section>
      {/* ======================== Products ======================== */}
      <section className="products">
        <div className="container">
          <header className="hidden">
            <h3 className="h3 title">Product category grid</h3>
          </header>
          <div className="row">
            {/* === product-filters === */}
            <div className="col-md-3 col-xs-12">
              <div className="filters">
                {/*Price*/}
                {/* <div className="filter-box active">
                  <div className="title">Price</div>
                  <div className="filter-content">
                    <div className="price-filter">
                      <input type="text" id="range-price-slider" defaultValue name="range" />
                    </div>
                  </div>
                </div> */}
                {/*Availability
                <div className="filter-box active">
                  <div className="title">
                    Availability
                  </div>
                  <div className="filter-content">
                    <span className="checkbox">
                      <input type="radio" name="radiogroup-stock" id="availableId1" defaultChecked="checked" />
                      <label htmlFor="availableId1">In stock <i>(152)</i></label>
                    </span>
                    <span className="checkbox">
                      <input type="radio" name="radiogroup-stock" id="availableId2" />
                      <label htmlFor="availableId2">1 Week <i>(100)</i></label>
                    </span>
                    <span className="checkbox">
                      <input type="radio" name="radiogroup-stock" id="availableId3" />
                      <label htmlFor="availableId3">2 Weeks <i>(80)</i></label>
                    </span>
                  </div>
                </div> */}{/*/filter-box*/}
                {/*Discount*/}
                {/* <div className="filter-box active">
                  <div className="title">
                    Discount
                  </div>
                  <div className="filter-content">
                    <span className="checkbox">
                      <input type="radio" id="discountId1" name="discountPrice" defaultChecked="checked" />
                      <label htmlFor="discountId1">Discount price</label>
                    </span>
                    <span className="checkbox">
                      <input type="radio" id="discountId2" name="discountPrice" />
                      <label htmlFor="discountId2">Regular price</label>
                    </span>
                  </div>
                </div> /filter-box */}
                {/*Type*/}
                <div className="filter-box active">
                  <div className="title">Categories</div>
                  <div className="filter-content">
                    {hasCategories && categories.categories && categories.categories.length > 0 ? (
                      <>
                        {/* Include "All" option */}
                        <span className="checkbox">
                          <input
                            type="radio"
                            name="radiogroup-type"
                            id="typeIdAll"
                            defaultChecked={selectedCategory === null} // Optionally set "All" as checked if no category is selected
                            onChange={() => changeSelectedCategory(null)}
                          />
                          <label htmlFor="typeIdAll">
                            All
                          </label>
                        </span>

                        {/* Render categories */}
                        {categories.categories.map((category, index) => (
                          <span className="checkbox" key={index}>
                            <input
                              type="radio"
                              name="radiogroup-type"
                              id={`typeId${category.Id}`}
                              defaultChecked={index === 0 && selectedCategory === null} // Optionally set the first category as checked by default if no category is selected
                              onChange={() => changeSelectedCategory(category.Id)}
                            />
                            <label htmlFor={`typeId${category.Id}`}>
                              {category.Name}
                            </label>
                          </span>
                        ))}
                      </>
                    ) : (
                      <p>No categories available.</p>
                    )}
                  </div>
                </div>
                {/*/filter-box*/}
                {/*Material*/}
                {/* <div className="filter-box active">
                  <div className="title">
                    Material
                  </div>
                  <div className="filter-content">
                    <span className="checkbox">
                      <input type="radio" name="radiogroup-material" id="matIdAll" />
                      <label htmlFor="matIdAll">All <i>(450)</i></label>
                    </span>
                    <span className="checkbox">
                      <input type="radio" name="radiogroup-material" id="matId1" />
                      <label htmlFor="matId1">Blend <i>(11)</i></label>
                    </span>
                    <span className="checkbox">
                      <input type="radio" name="radiogroup-material" id="matId2" />
                      <label htmlFor="matId2">Leather <i>(12)</i></label>
                    </span>
                    <span className="checkbox">
                      <input type="radio" name="radiogroup-material" id="matId3" />
                      <label htmlFor="matId3">Wood <i>(80)</i></label>
                    </span>
                    <span className="checkbox">
                      <input type="radio" name="radiogroup-material" id="matId4" />
                      <label htmlFor="matId4">Shell <i>(80)</i></label>
                    </span>
                    <span className="checkbox">
                      <input type="radio" name="radiogroup-material" id="matId5" />
                      <label htmlFor="matId5">Metal <i>(80)</i></label>
                    </span>
                    <span className="checkbox">
                      <input type="radio" name="radiogroup-material" id="matId6" />
                      <label htmlFor="matId6">Aluminium <i>(80)</i></label>
                    </span>
                    <span className="checkbox">
                      <input type="radio" name="radiogroup-material" id="matId7" />
                      <label htmlFor="matId7">Acrilic <i>(80)</i></label>
                    </span>
                  </div>
                </div> /filter-box */}
                {/*close filters on mobile / update filters*/}
                {/* <div className="toggle-filters-close btn btn-main">
                  Update search
                </div> */}
              </div> {/*/filters*/}
            </div>
            {/*product items*/}

            <div className="col-md-9 col-xs-12">
              {/* <div className="sort-bar clearfix">
                <div className="sort-results pull-left">
                  <select>
                    <option value={1}>10</option>
                    <option value={2}>50</option>
                    <option value={3}>100</option>
                    <option value={4}>All</option>
                  </select>
                  <span>Showing all <strong>50</strong> of <strong>3,250</strong> items</span>
                </div>
                <div className="sort-options pull-right">
                  <span className="hidden-xs">Sort by</span>
                  <select>
                    <option value={1}>Name</option>
                    <option value={2}>Popular items</option>
                    <option value={3}>Price: lowest</option>
                    <option value={4}>Price: highest</option>
                  </select>
                  <span className="grid-list">
                    <a href="products-grid.html"><i className="fa fa-th-large" /></a>
                    <a href="products-list.html"><i className="fa fa-align-justify" /></a>
                    <a href="#!" className="toggle-filters-mobile"><i className="fa fa-search" /></a>
                  </span>
                </div>
              </div> */}

              <div className="row">
                {/* {filteredProducts.map(product => (
                  <div key={product.id} className="col-md-6 col-xs-6">
                    <article>
                      <div className="info">
                        <span className="add-favorite">
                          <a href="#!" data-title="Add to favorites" data-title-added="Added to favorites list"><i className="icon icon-heart" /></a>
                        </span>
                        <span>
                          <a href={`#productid${product.id}`} className="mfp-open" data-title="Quick view"><i className="icon icon-eye" /></a>
                        </span>
                      </div>
                      <div className="btn btn-add">
                        <i className="icon icon-cart" />
                      </div>
                      <div className="figure-grid">
                        {product.label && <span className={`label ${product.labelClass}`}>{product.label}</span>}
                        <div className="image">
                          <a href={`#productid${product.id}`} className="mfp-open">
                            <img src={product.image} alt={product.title} width={360} />
                          </a>
                        </div>
                        <div className="text">
                          <h2 className="title h4"><a href="product.html">{product.title}</a></h2>
                          {product.oldPrice && <sub>{product.oldPrice}</sub>}
                          <sup>{product.newPrice}</sup>
                          <span className="description clearfix">{product.description}</span>
                        </div>
                      </div>
                    </article>
                  </div>
                ))} */}
                {hasProducts && filteredProducts.length > 0 ? (
                  currentProducts.map((product, index) => (
                    <React.Fragment key={product.Id}> 


                      <div className="col-md-6 col-xs-6">
                        <article>
                          <div className="info">
                            <span className="add-favorite added">
                              <a href="#" onClick={(e) => e.preventDefault()} data-title="Add to favorites" data-title-added="Added to favorites list"><i className="icon icon-heart" /></a>
                            </span>
                            <span>
                              <a href={`/product/${product.Id}/${product.Slug}`}><i className="icon icon-eye" /></a>
                            </span>
                          </div>
                          <Link to={`/product/${product.Id}/${product.Slug}`}>
                          <div className="btn btn-add">
                            <i className="icon icon-cart" />
                          </div>
                          </Link>
                          <br />
                          <br />
                          <br />
                          <br />
                          <div className="figure-grid">
                            <div className="image">
                              <Link to={`/product/${product.Id}/${product.Slug}`}>
                                <center><img src={`${IMG_BASE_URL}${product.Thumbnai}`} style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '270px' }} /></center>
                              </Link>
                            </div>
                            <br />
                            <br />
                            <div className="text">
                              <h2 className="title h4"><Link to={`/product/${product.Id}/${product.Slug}`}>{product.Name} ({product.ArticleNo})</Link></h2>
                              {/* <sub>Rs {product.SalePrice}</sub>  */}
                              <sup>Rs {product.SalePrice}</sup>
                              <span className="description clearfix">{product.LongDescription}</span>
                            </div>
                          </div>
                        </article>
                      </div>

                      </React.Fragment> 

                  ))
                ) : (
                  <>
                    <div className="panel panel-default">
                      <div className='panel-body'>
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        <br /><br />
                        <center><h1 style={{ fontSize: '50px' }}>No Products Available</h1></center>
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        <br /><br />

                      </div>

                    </div>
                  </>

                )}
                 {/* {filteredProducts.length > 0 ? (
        currentProducts.map((product, index) => (
          <div key={index} className="col-md-4 col-xs-6">
            <article>
              <div className="info">
                <span className="add-favorite added">
                  <a href="#" onClick={(e) => e.preventDefault()} data-title="Add to favorites" data-title-added="Added to favorites list"><i className="icon icon-heart" /></a>
                </span>
                <span>
                  <a href={`/product/${product.Id}/${product.Slug}`}><i className="icon icon-eye" /></a>
                </span>
              </div>
              <div className="btn btn-add">
                <i className="icon icon-cart" />
              </div>
              <div className="figure-grid">
                <div className="image">
                  <a href={`/product/${product.Id}/${product.Slug}`}>
                    <center><img src={`${IMG_BASE_URL}${encodeURIComponent(product.Thumbnail)}`} alt={product.Name} style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '270px' }} /></center>
                  </a>
                </div>
                <div className="text">
                  <h2 className="title h4"><Link to={`/product/${product.Id}/${product.Slug}`}>{product.Name}</Link></h2>
                  <sub>Rs {product.SalePrice}</sub>
                  <sup>Rs {product.SalePrice}</sup>
                  <span className="description clearfix">{product.LongDescription}</span>
                </div>
              </div>
            </article>
          </div>
        ))
      ) : (
        <div className="panel panel-default">
          <div className='panel-body'>
            <center><h1 style={{ fontSize: '50px' }}>No Products Available</h1></center>
          </div>
        </div>
      )} */}
              </div>
                
              {/* <div className="pagination-wrapper">
                <ul className="pagination">
                  <li>
                    <a href="#!" aria-label="Previous">
                      <span aria-hidden="true">«</span>
                    </a>
                  </li>
                  {pagination.map(page => (
                    <li key={page} className={page === 1 ? 'active' : ''}>
                      <a href="#!">{page}</a>
                    </li>
                  ))}
                  <li>
                    <a href="#!" aria-label="Next">
                      <span aria-hidden="true">»</span>
                    </a>
                  </li>
                </ul>
              </div> */}
              { /* Pagination */}
              <div className="pagination-wrapper">
                <ul className="pagination">
                  <li className={currentPage === 1 ? 'disabled' : ''}>
                    <a href="#!" aria-label="Previous" onClick={() => handleClick(currentPage - 1)} disabled={currentPage === 1}>
                      <span aria-hidden="true">«</span>
                    </a>
                  </li>
                  {[...Array(totalPages)].map((_, page) => (
                    <li key={page + 1} className={currentPage === page + 1 ? 'active' : ''}>
                      <a href="#!" onClick={() => handleClick(page + 1)}>{page + 1}</a>
                    </li>
                  ))}
                  <li className={currentPage === totalPages ? 'disabled' : ''}>
                    <a href="#!" aria-label="Next" onClick={() => handleClick(currentPage + 1)} disabled={currentPage === totalPages}>
                      <span aria-hidden="true">»</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>{/*/row*/}
          {/* ========================  Product info popup - quick view ======================== */}
          {/* <div className="popup-main mfp-hide" id="productid1">
            <div className="product">
              <div className="popup-title">
                <div className="h1 title">Laura <small>product category</small></div>
              </div>
              <div className="owl-product-gallery">
                <img src="assets/images/product-1.png" alt="test" width={640} />
                <img src="assets/images/product-2.png" alt="test" width={640} />
                <img src="assets/images/product-3.png" alt="test" width={640} />
                <img src="assets/images/product-4.png" alt="test" width={640} />
              </div>
       
              <div className="popup-content">
                <div className="product-info-wrapper">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="info-box">
                        <strong>Maifacturer</strong>
                        <span>Brand name</span>
                      </div>
                      <div className="info-box">
                        <strong>Materials</strong>
                        <span>Wood, Leather, Acrylic</span>
                      </div>
                      <div className="info-box">
                        <strong>Availability</strong>
                        <span><i className="fa fa-check-square-o" /> in stock</span>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="info-box">
                        <strong>Available Colors</strong>
                        <div className="product-colors clearfix">
                          <span className="color-btn color-btn-red" />
                          <span className="color-btn color-btn-blue checked" />
                          <span className="color-btn color-btn-green" />
                          <span className="color-btn color-btn-gray" />
                          <span className="color-btn color-btn-biege" />
                        </div>
                      </div>
                      <div className="info-box">
                        <strong>Choose size</strong>
                        <div className="product-colors clearfix">
                          <span className="color-btn color-btn-biege">S</span>
                          <span className="color-btn color-btn-biege checked">M</span>
                          <span className="color-btn color-btn-biege">XL</span>
                          <span className="color-btn color-btn-biege">XXL</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="popup-table">
                <div className="popup-cell">
                  <div className="price">
                    <span className="h3">Rs. 1999,00 <small>Rs. 2999,00</small></span>
                  </div>
                </div>
                <div className="popup-cell">
                  <div className="popup-buttons">
                    <a href="product.html"><span className="icon icon-eye" /> <span className="hidden-xs">View more</span></a>
                    <Link to="/checkout1"><span className="icon icon-cart" /> <span className="hidden-xs">Buy</span></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>  */}


        </div>
      </section>
    </>
  )
}
